import { FunctionComponent, useEffect, useRef } from 'react';
import { Button, ButtonProps, IconButton } from '@common/components/buttons';
import { MenuIcon } from '@/app/assets/icons/menu';
import classNames from 'classnames';
import { useDeployment, DeploymentProps } from './hooks/useDeployment';

export type DropdownMenuProps = {
  children: React.ReactNode;
} & DeploymentProps;

const DropdownMenuComponent: FunctionComponent<DropdownMenuProps> = ({
  children,
  deployed,
  onDeploy,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isDeployed, handleDeploy] = useDeployment(
    onDeploy ? { onDeploy, deployed } : {},
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        if (isDeployed) {
          handleDeploy(false); // Close the menu when clicking outside
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDeployed, handleDeploy]);

  return (
    <div className="relative" ref={menuRef}>
      <IconButton
        onClick={() => handleDeploy()}
        className="flex justify-center items-center w-[60px] self-end "
        imageClassName="fill-color-dark-grey2"
        Icon={MenuIcon}
      />{' '}
      {isDeployed && (
        <div className="absolute flex flex-col items-stretch  justify-items-stretch right-4 top-6 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50">
          {children}
        </div>
      )}
    </div>
  );
};

export type ItemProps = {
  title: string;
} & ButtonProps;

const DropdownItem: FunctionComponent<ItemProps> = ({
  title,
  className,
  ...props
}) => {
  const actualClassName = classNames(
    ' h-[50px] px-[35px] hover:bg-color-light-grey',
    className,
  );

  return (
    <Button {...props} className={actualClassName}>
      {title}
    </Button>
  );
};

export const DropdownMenu = Object.assign(DropdownMenuComponent, {
  Item: DropdownItem,
});
