import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { useCurrentUser } from '@/modules/authentification/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PayslipFunnel } from '@/app/spraypaint';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { IcnBtnSalaire } from '../../../app/assets/icons/payslip';
import { ButtonPrimary } from '../../common/components/buttons';
import { Line } from '../../common/components/fragments';
import { StepCounter, StepCounterProps } from './StepCounter';
import PayslipPeriodStringified from '../../payslip_funnel/components/PayslipPeriodStringified';
import { PeriodType } from '../../common/utils/type.utils';
import useBreakpoints from '../../../app/responsive/useBreakPoints';
import { DropdownMenu } from '../../common/components/DropdownMenu/DropdownMenu';

const CardMenu: FunctionComponent<{
  currentPayslipFunnel: PayslipFunnel | undefined;
}> = ({ currentPayslipFunnel }) => {
  const { user } = useCurrentUser();
  const roles = user?.roles;
  const queryClient = useQueryClient();
  const employeeId = useCurrentEmployeeId();

  const { mutateAsync } = useMutation({
    mutationFn: () => {
      if (
        window.confirm(
          "Voulez-vous vraiment supprimer ce Tunnel de Salaire (TDS) ? Cette action n'est pas annulable.",
        )
      ) {
        return currentPayslipFunnel?.destroy() ?? Promise.resolve(false);
      }
      return Promise.resolve(false);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['employee-payslip-funnel', employeeId]);
    },
  });

  if (!roles || !roles?.includes('support')) return null;

  return (
    <DropdownMenu>
      {' '}
      <DropdownMenu.Item title="Supprimer" onClick={() => mutateAsync()} />{' '}
    </DropdownMenu>
  );
};

export type PayslipStartedProps = {
  currentPayslipFunnel: PayslipFunnel | undefined;
  nextStepName: string;
  currentStepName: string;
  period: PeriodType;
} & StepCounterProps;

export const PayslipStarted = ({
  currentPayslipFunnel,
  currentStep,
  totalStep,
  nextStepName,
  currentStepName,
  period,
}: PayslipStartedProps) => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  return (
    <div className="md:shadow-card md:w-[680px] md:ml-[30px] pt-[19px] mt-[22px]">
      <div className="flex justify-between">
        <PayslipPeriodStringified period={period} />{' '}
        <CardMenu currentPayslipFunnel={currentPayslipFunnel} />
      </div>
      {!isXs && <Line rounded />}
      <div className="p-[21px]">
        <div className="relative flex flex-col md:w-[638px] h-[112px] ">
          <div className="flex flex-col pl-[180px] flex-1 rounded-t-lg text-lg justify-center bg-color-pastel-orange">
            <p>{t('front.payslip.index.header.payslipStarted.currentStep')}</p>
            <p className="font-cabin-bold">› {t(currentStepName)}</p>
          </div>
          <div className="flex flex-1 flex-col pl-[190px] text-base2 text-color-dark-grey2 rounded-b-lg justify-center bg-color-transparent-medium-grey">
            {nextStepName && (
              <>
                <p>{t('front.payslip.index.header.payslipStarted.nextStep')}</p>
                <p className="font-cabin-bold">› {t(nextStepName)}</p>
              </>
            )}
          </div>
          <StepCounter
            currentStep={currentStep}
            className="absolute left-[25px] md:left-[50px] top-[15px]"
            totalStep={totalStep}
          />{' '}
          {!isXs && (
            <ButtonPrimary
              to="create"
              className="w-[154px] absolute right-[10px] top-[30px]"
              Icon={IcnBtnSalaire}
            >
              {t('front.payslip.index.header.payslipStarted.button.title')}
            </ButtonPrimary>
          )}
        </div>
        <div className="flex justify-center">
          {isXs && (
            <ButtonPrimary
              to="create"
              className="w-[154px] md:hidden block mt-[10px]"
              Icon={IcnBtnSalaire}
            >
              {t('front.payslip.index.header.payslipStarted.button.title')}
            </ButtonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};
