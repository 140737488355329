import PayslipFunnel from '../../../app/spraypaint/payslipFunnel/PayslipFunnel';
import {
  PayslipFunnelStepMapping,
  PayslipFunnelStepMappingType,
} from '../types/PayslipIndex';
import { NoPayslipStarted } from './NoPayslipStarted';
import { PayslipStarted } from './PayslipStarted';
import { PeriodType } from '../../common/utils/type.utils';

type Props = {
  currentPayslipFunnel: PayslipFunnel | undefined;
  nextPayslipFunnelPeriod: PeriodType | undefined;
  nextFunnelAutoStartDate: string | Date;
};

export const PayslipIndexCards = ({
  currentPayslipFunnel,
  nextPayslipFunnelPeriod,
  nextFunnelAutoStartDate,
}: Props) => {
  if (currentPayslipFunnel) {
    const currentPayslipFunnelStatus = currentPayslipFunnel?.attributes?.status;
    // get current step name
    const currentStepName =
      PayslipFunnelStepMapping[
        currentPayslipFunnelStatus as keyof PayslipFunnelStepMappingType
      ];

    const keys = Object.keys(PayslipFunnelStepMapping);
    // get next step name
    const nextIndex = keys.indexOf(currentPayslipFunnelStatus) + 1;
    const nextItem = keys[nextIndex];
    const nextStepName =
      PayslipFunnelStepMapping[nextItem as keyof PayslipFunnelStepMappingType];

    // get current step index
    const currentStep =
      Object.keys(PayslipFunnelStepMapping).findIndex(
        (element) => element === currentPayslipFunnelStatus,
      ) + 1;

    // get total step value
    const totalStep = Object.keys(PayslipFunnelStepMapping).length;

    return (
      <PayslipStarted
        currentPayslipFunnel={currentPayslipFunnel}
        period={currentPayslipFunnel.period}
        currentStepName={currentStepName}
        nextStepName={nextStepName}
        currentStep={currentStep}
        totalStep={totalStep}
      />
    );
  }
  if (nextPayslipFunnelPeriod)
    return (
      <NoPayslipStarted
        nextPayslipFunnelPeriod={nextPayslipFunnelPeriod}
        nextFunnelAutoStartDate={nextFunnelAutoStartDate}
      />
    );

  return null;
};
