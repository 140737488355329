import { useCallback, useState } from 'react';

export type DeploymentHandler = (forcedState?: boolean | undefined) => void;

export type DeploymentProps =
  | {
      onDeploy: DeploymentHandler;
      deployed: boolean;
    }
  | {
      onDeploy?: undefined;
      deployed?: undefined;
    };

export function useDeployment({
  onDeploy,
  deployed,
}: DeploymentProps): readonly [boolean, DeploymentHandler] {
  const [isDeployed, setIsDeployed] = useState(false);
  const isControlled = onDeploy !== undefined;

  const handleOnClick = useCallback(() => {
    if (isControlled) {
      onDeploy();
    } else {
      setIsDeployed((prev) => !prev);
    }
  }, [isControlled, onDeploy]);

  const state: boolean = isControlled ? deployed ?? false : isDeployed;

  return [state, handleOnClick] as const;
}
